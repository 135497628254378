import React, { useEffect } from "react"
import {
  Box,
  FormControl,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  SelectChangeEvent,
  TextField,
} from "@mui/material"
import dayjs, { Dayjs } from "dayjs"
import { DateRangeOutlined } from "@mui/icons-material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { DemoContainer } from "@mui/x-date-pickers/internals/demo"
import DateTimeWrapper from "../DateTimeWrapper/DateTimeWrapper"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { StyledFormControl } from "../../containers/Transactions/styles"
import Popover from "@mui/material/Popover"
import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from "material-ui-popup-state/hooks"
import { DateFilterLabels, DateFilterValues } from "../../constants/data"

interface DateRangeSelectorProps {
  dateSelector: string
  filterState: {
    dateSelector: string
    startDate: Dayjs | null
    endDate: Dayjs | null
  }
  updateFilterState: (newState: {
    dateSelector: string
    startDate?: Dayjs | null
    endDate?: Dayjs | null
  }) => void
  disable?: boolean
}

const DateRangeSelector: React.FC<DateRangeSelectorProps> = ({
  dateSelector,
  filterState,
  updateFilterState,
}) => {
  // Function to determine if the "Today" filter should prioritize yesterday's data
  function prioritizeTodayFilter() {
    if (
      filterState.dateSelector === DateFilterValues.TODAY &&
      filterState.startDate &&
      !dayjs().isSame(filterState.startDate, "day")
    ) {
      updateFilterState({
        dateSelector: DateFilterValues.YESTERDAY,
        startDate: dayjs().subtract(1, "day").startOf("day"),
        endDate: dayjs().subtract(1, "day").endOf("day"),
      })
    }
  }

  useEffect(() => {
    prioritizeTodayFilter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState])

  const popupState = usePopupState({
    variant: "popover",
    popupId: "dateFilterPopover",
  })

  const handleDateRangeChange = (event: SelectChangeEvent<string>) => {
    const {
      target: { value },
    } = event
    // Update startDate and endDate based on selected date range
    switch (value) {
      case DateFilterValues.TODAY:
        updateFilterState({
          dateSelector: DateFilterValues.TODAY,
          startDate: dayjs(),
          endDate: dayjs(),
        })
        break
      case DateFilterValues.YESTERDAY:
        updateFilterState({
          dateSelector: DateFilterValues.YESTERDAY,
          startDate: dayjs().subtract(1, "day"),
          endDate: dayjs().subtract(1, "day"),
        })
        break
      case DateFilterValues.SEVEN_DAYS:
        updateFilterState({
          dateSelector: DateFilterValues.SEVEN_DAYS,
          startDate: dayjs().subtract(7, "day"),
          endDate: dayjs(),
        })
        break
      case DateFilterValues.THRITY_DAYS:
        updateFilterState({
          dateSelector: DateFilterValues.THRITY_DAYS,
          startDate: dayjs().subtract(30, "day"),
          endDate: dayjs(),
        })
        break
      case DateFilterValues.WEEK:
        updateFilterState({
          dateSelector: DateFilterValues.WEEK,
          startDate: dayjs().startOf("week"),
          endDate: dayjs(),
        })
        break
      case DateFilterValues.MONTH:
        updateFilterState({
          dateSelector: DateFilterValues.MONTH,
          startDate: dayjs().startOf("month"),
          endDate: dayjs(),
        })
        break
      case DateFilterValues.QUARTER: {
        const currentMonth = dayjs().month()
        const quarterStartMonth = Math.floor(currentMonth / 3) * 3 // Calculate start month of current quarter
        const quarterStartDate = dayjs()
          .startOf("year")
          .month(quarterStartMonth) // Set start date to the calculated month
        updateFilterState({
          dateSelector: DateFilterValues.QUARTER,
          startDate: quarterStartDate,
          endDate: dayjs(),
        })
        break
      }
      case DateFilterValues.CUSTOM:
        updateFilterState({
          dateSelector: DateFilterValues.CUSTOM,
        })
        break
    }
  }

  const renderCustomDatePickers = () => (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DatePicker"]}>
        <DateTimeWrapper style={{ width: "100%" }}>
          <DatePicker
            label="Start Date"
            format="DD/MM/YYYY"
            value={filterState.startDate ? dayjs(filterState.startDate) : null}
            onChange={(newValue) =>
              updateFilterState({
                ...filterState,
                startDate: newValue,
              })
            }
            sx={{ width: "100%" }}
          />
        </DateTimeWrapper>
      </DemoContainer>
      <DemoContainer components={["DatePicker"]}>
        <DateTimeWrapper style={{ width: "100%" }}>
          <DatePicker
            label="End Date"
            format="DD/MM/YYYY"
            value={filterState.endDate ? dayjs(filterState.endDate) : null}
            onChange={(newValue) =>
              updateFilterState({
                ...filterState,
                endDate: newValue,
              })
            }
            sx={{ width: "100%" }}
          />
        </DateTimeWrapper>
      </DemoContainer>
    </LocalizationProvider>
  )

  const getDateFileterLabel = (value: string) => {
    return DateFilterLabels[value as DateFilterValues] || ""
  }

  return (
    <StyledFormControl size="small">
      <TextField
        {...bindTrigger(popupState)}
        size="small"
        id="date-range-id"
        value={getDateFileterLabel(dateSelector)}
        label="Date Range"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <DateRangeOutlined fontSize="small" />
            </InputAdornment>
          ),
          slotProps: {
            input: {
              readOnly: true,
              style: {
                cursor: "pointer",
              },
            },
          },
        }}
      ></TextField>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box
          sx={{
            background: "white",
            padding: "16px",
            borderRadius: "4px",
            width: "250px",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <FormControl size="small">
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              value={dateSelector}
              onChange={handleDateRangeChange}
            >
              {Object.values(DateFilterValues).map((filterValue) => (
                <FormControlLabel
                  key={filterValue}
                  value={filterValue}
                  control={<Radio />}
                  label={getDateFileterLabel(filterValue)}
                />
              ))}
            </RadioGroup>
          </FormControl>
          {dateSelector === DateFilterValues.CUSTOM &&
            renderCustomDatePickers()}
        </Box>
      </Popover>
    </StyledFormControl>
  )
}

export default DateRangeSelector
