import { Typography } from "@mui/material"
import { styled } from "@mui/material/styles"

export const Wrapper = styled("div")`
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  gap: 6px;
  flex-direction: column;
  position: sticky;
  top: 0px;
  z-index: 1000;
`

export const HeaderContent = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    gap: "10px",
    alignItems: "initial",
  },
}))

export const TitleContainer = styled("div")(({ theme }) => ({
  width: "max-content",
  height: "40px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  background:
    "linear-gradient(90deg, rgba(255, 193, 0, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%)",
  [theme.breakpoints.down("sm")]: {
    marginLeft: "30px",
  },
}))

export const VerticalDiv = styled("div")`
  width: 4px;
  height: inherit;
  margin-right: 12px;
  background-color: #ffc100;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`

export const StyledText = styled(Typography)`
  font-size: 24px;
  font-weight: 500;
`
