import React, { useCallback } from "react"
import { useField, useFormikContext } from "formik"
import { useDispatch, useSelector } from "react-redux"
import { Autocomplete, TextField } from "@mui/material"
import debounce from "lodash/debounce"
import {
  fetchInvoicesRequest,
  searchCustomersRequest,
  setSelectedCustomer,
} from "../../slices/reconcilationSlice"
import { Customer } from "../../models/customer.model"
import { CustomerLabel } from "./styles"
import { RootState } from "../../app/store"
import { ReconciliationFormValues } from "../../models/reconciliation.model"

export interface CustomerSelectorProps {
  transactionSource: string
}

export const CustomerSelector: React.FC<CustomerSelectorProps> = ({
  transactionSource,
}) => {
  const dispatch = useDispatch()
  const [field, meta] = useField<Customer>("customer")
  const { setFieldValue, handleBlur } =
    useFormikContext<ReconciliationFormValues>()
  const { customers } = useSelector((state: RootState) => state.reconciliation)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((searchText: string) => {
      dispatch(
        searchCustomersRequest({
          searchTerm: searchText,
          transactionSource,
        }),
      )
    }, 300),
    [dispatch],
  )

  const handleCustomerChange = (_: any, newValue: Customer | null) => {
    dispatch(setSelectedCustomer(newValue))
    setFieldValue("customer", newValue)
    setFieldValue("reconciliationItems", [])
    if (newValue) {
      dispatch(fetchInvoicesRequest(newValue.contactId))
    }
  }

  const getAutoCompleteOptions = () => {
    const hasCustomer = customers.some(
      (customer) => customer.id === field.value?.id,
    )
    return field.value
      ? [...customers, ...(hasCustomer ? [] : [field.value])]
      : customers
  }

  return (
    <>
      <CustomerLabel>Customer Name*</CustomerLabel>
      <Autocomplete<Customer>
        options={getAutoCompleteOptions()}
        getOptionLabel={(option) => option.name}
        getOptionKey={(option) => option.id}
        value={field.value}
        onChange={handleCustomerChange}
        onInputChange={(_, value) => debouncedSearch(value)}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        fullWidth
        size="small"
        renderInput={(params) => (
          <TextField
            {...params}
            name="customer"
            label=""
            size="small"
            onBlur={handleBlur}
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
          />
        )}
      />
    </>
  )
}
