export class Transaction {
  id = ""
  date = ""
  description = ""
  amount = 0
  type = ""
  referenceNo = ""
  valueDate = ""
  branch = ""
  balance = 0
  isMarked = false
  invoiceNumber = ""
  invoiceId = ""
  invoiceURL = ""
  paymentId = ""
  paymentURL = ""
  payee = ""
  accountName = ""
  isReconciled = false
  isSynced = false
  invoiceSource = ""
  settlements = { payments: [], invoices: [] }
  constructor(transaction?: any) {
    if (transaction) {
      this.id = transaction?.id
      this.date = transaction?.date
      this.description = transaction?.description
      this.amount = transaction?.amount
      this.type = transaction?.type
      this.referenceNo = transaction?.reference_no
      this.valueDate = transaction?.value_date
      this.branch = transaction?.branch
      this.balance = transaction?.balance
      this.isMarked = transaction?.is_marked
      this.isReconciled = transaction?.is_reconciled
      this.isSynced = transaction?.is_synced
      this.invoiceNumber = transaction?.invoice_number
      this.invoiceId = transaction?.invoice_id
      this.invoiceURL = transaction?.invoice_url
      this.paymentId = transaction?.payment_id
      this.paymentURL = transaction?.payment_url
      this.payee = transaction?.payee
      this.accountName = transaction?.account_name
      this.settlements = transaction?.settlements
      this.invoiceSource = transaction?.invoice_source
    }
  }
}

export class Bank {
  id = ""
  accountName = ""

  constructor(bank?: any) {
    if (bank) {
      this.id = bank?.id
      this.accountName = bank?.account_name
    }
  }
}

export class ProcessSchedule {
  message = ""
  status = false
  nextRun = ""
  scheduleStatus = ""
  id = ""
  name = ""
  constructor(ProcessSchedule?: any) {
    if (ProcessSchedule) {
      this.message = ProcessSchedule?.message
      this.status = ProcessSchedule?.data?.status
      this.nextRun = ProcessSchedule?.data?.details?.next_run
      this.scheduleStatus = ProcessSchedule?.data?.details?.status
      this.id = ProcessSchedule?.data?.details?.id
      this.name = ProcessSchedule?.data?.details?.name
    }
  }
}
