export const ExternalLinkIcon = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginLeft: "4px" }}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.35449 1.00001H8.85449V7.50001H7.85449V2.70701L1.70849 8.85401L1.00049 8.14601L7.14749 2.00001H2.35449V1.00001Z"
        fill="#2D49E1"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.05445 0.700012H9.15445V7.80001H7.55445V3.43136L1.70847 9.27829L0.576172 8.146L6.42311 2.30001H2.05445V0.700012ZM7.14749 2.00001L1.00049 8.14601L1.70849 8.85401L7.85449 2.70701V7.50001H8.85449V1.00001H2.35449V2.00001H7.14749Z"
        fill="#2D49E1"
      />
    </svg>
  )
}
