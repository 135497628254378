import { Breadcrumbs, Link, Typography, useMediaQuery } from "@mui/material"
import {
  HeaderContent,
  StyledText,
  TitleContainer,
  VerticalDiv,
  Wrapper,
} from "./styles"
import { NavLink } from "react-router-dom"
import theme from "../../app/theme"

type BreadcrumsType = {
  title: string
  path?: string
}

type PageHeaderProps = {
  title: string
  breadcrums?: BreadcrumsType[]
  actions?: JSX.Element
}

const PageHeader = ({ title, breadcrums = [], actions }: PageHeaderProps) => {
  const isNotSmallScreen = useMediaQuery(theme.breakpoints.up("sm"))
  return (
    <Wrapper>
      {breadcrums.length > 0 && isNotSmallScreen && (
        <Breadcrumbs aria-label="breadcrumb">
          {breadcrums.map((breadcrum, index) =>
            breadcrum.path ? (
              <Link
                component={NavLink}
                to={breadcrum.path}
                underline="hover"
                color="primary"
                key={index}
              >
                {breadcrum.title}
              </Link>
            ) : (
              <Typography key={index}>{breadcrum.title}</Typography>
            ),
          )}
        </Breadcrumbs>
      )}
      <HeaderContent>
        <TitleContainer>
          <VerticalDiv />
          <StyledText>{title}</StyledText>
        </TitleContainer>
        {actions}
      </HeaderContent>
    </Wrapper>
  )
}

export default PageHeader
