export interface CustomerSearchResponse {
  contacts: CustomerResponse[]
}

export interface CustomerResponse {
  id: string
  contact_id: string
  name: string
}

export class Customer {
  id: string
  contactId: string
  name: string
  constructor(customerResponse?: CustomerResponse) {
    this.id = customerResponse?.id ?? ""
    this.contactId = customerResponse?.contact_id ?? ""
    this.name = customerResponse?.name ?? ""
  }
}
