import { GridColDef, GridRowsProp } from "@mui/x-data-grid"
import dayjs from "dayjs"
import { Link, useParams } from "react-router-dom"
import { CENTERS, USERS } from "../../constants/path"
import userCheck from "../../utils/userCheck"
import { useAppSelector } from "../../app/hooks"
import { selectAuditLogs } from "../../slices/auditLogsSlice"
import Table from "../../components/Table/Table"

interface AuditLogsTableProps {
  rowCount: number
  paginationModel: any
  onPaginationModelChange: (model: any) => void
  isSmallScreen: boolean
}

const AuditLogsTable = ({
  rowCount,
  paginationModel,
  onPaginationModelChange,
}: AuditLogsTableProps) => {
  const { centerLogs, isCenterLogsLoading } = useAppSelector(selectAuditLogs)

  const { userId } = useParams()

  const rows: GridRowsProp = centerLogs.map((log) => ({
    id: log?.id,
    user: log.user,
    centerId: log?.center_id,
    centerName: log?.centerName,
    action: log?.type,
    purpose: log?.purpose,
    status: log?.status,
    datetime: dayjs
      .utc(log?.scanned_at)
      .utcOffset("+05:30")
      .format("YYYY-MM-DD hh:mm:ss A"),
    bookingCode: log?.day_pass_booking_details?.booking_code || "",
    daypassType: log?.day_pass_booking_details?.daypass_type || "",
    bookingDate:
      dayjs
        .utc(log?.day_pass_booking_details?.booking_date)
        .utcOffset("+05:30")
        .format("DD/MM/YYYY") || "",
  }))

  let columns: GridColDef[] = [
    {
      field: "center",
      headerName: "Center",
      width: 200,
      renderCell: ({ row: { centerName, centerId } }) => {
        return (
          <Link
            to={`${CENTERS}/details/${centerId}`}
            style={{ textDecoration: "none", color: "black" }}
          >
            {centerName}
          </Link>
        )
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
    },
    { field: "purpose", headerName: "Purpose", width: 150 },
    { field: "status", headerName: "Status", width: 150 },
    { field: "datetime", headerName: "Scan Datetime", width: 200 },
    { field: "bookingCode", headerName: "Booking Code", width: 150 },
    { field: "daypassType", headerName: "Pass Type", width: 150 },
    { field: "bookingDate", headerName: "Booking Date", width: 150 },
  ]

  // Conditionally exclude the "User" column if userId exists
  if (!userId) {
    columns = [
      {
        field: "user",
        headerName: "User",
        width: 200,
        renderCell: ({ row: { user } }) => {
          return (
            <Link
              to={{
                pathname: `${USERS}/${user?.id}/profile`,
              }}
              state={{
                title: "User Details",
                prevPage: "Audit Logs",
                path: location.pathname,
              }}
              style={{ textDecoration: "none", color: "black" }}
            >
              {userCheck(
                `${user?.first_name} ${user?.last_name}`,
                "Unknown User",
              )}
            </Link>
          )
        },
      },
      ...columns,
    ]
  }

  return (
    <Table
      auditLogsTable
      rows={rows}
      columns={columns}
      loading={isCenterLogsLoading}
      rowCount={rowCount}
      pageSizeOptions={[10]}
      paginationModel={paginationModel}
      paginationMode="server"
      onPaginationModelChange={onPaginationModelChange}
    />
  )
}

export default AuditLogsTable
