import { configureStore } from "@reduxjs/toolkit"
import rootSaga from "./rootSaga"
import createSagaMiddleware from "@redux-saga/core"
import ToastReducer from "../slices/toastSlice"
import LoginReducer from "../slices/loginSlice"
import DaypassBookingReducer from "../slices/daypassBookingSlice"
import BookingHistoryReducer from "../slices/bookingHistorySlice"
import CentersReducer from "../slices/centersSlice"
import MeetingRoomsReducer from "../slices/meetingRoomsSlice"
import UsersReducer from "../slices/usersSlice"
import VersionDialogReducer from "../containers/VersionDialog/slice"
import AuditLogsReducer from "../slices/auditLogsSlice"
import transactionsReducer from "../slices/transactionsSlice"
import bankAccountsReducer from "../slices/bankAccountsSlice"
import reconciliationReducer from "../slices/reconcilationSlice"

const saga = createSagaMiddleware()

export const store = configureStore({
  reducer: {
    toast: ToastReducer,
    login: LoginReducer,
    daypassBooking: DaypassBookingReducer,
    bookingHistory: BookingHistoryReducer,
    centers: CentersReducer,
    meetingRooms: MeetingRoomsReducer,
    users: UsersReducer,
    versionDialog: VersionDialogReducer,
    auditLogs: AuditLogsReducer,
    transactions: transactionsReducer,
    bankAccounts: bankAccountsReducer,
    reconciliation: reconciliationReducer,
  },
  middleware: [saga],
})

saga.run(rootSaga)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
