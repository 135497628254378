import React, { Suspense, lazy, useEffect, useState } from "react"
import { Link, useLocation, useParams } from "react-router-dom"
import { Box, Card, Paper, Tab, Tabs } from "@mui/material"
import PageHeader from "../../components/PageHeader/PageHeader"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import Profile from "./Profile"
import { StyledCard } from "./styles"
import { USERS } from "../../constants/path"

const BookingHistory = lazy(() => import("../BookingHistory"))
const AuditLogs = lazy(() => import("../AuditLogs"))

type TabPanelProps = {
  children: React.ReactNode
  value: string
  index: string
}

const UserProfile = () => {
  const { userId } = useParams()
  const location = useLocation()
  const { pathname } = location
  const { title, prevPage, path } = (location.state as {
    title: string
    prevPage: string
    path: string
  }) || { title: "Profile", prevPage: "Booking History", path: "/" }

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"))
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("lg"))

  const [activeTab, setActiveTab] = useState("/profile") // Default active tab is "profile"

  useEffect(() => {
    // Determine active tab based on pathname
    if (pathname.includes("/profile")) {
      setActiveTab("/profile")
    } else if (pathname.includes("/booking-history")) {
      setActiveTab("/booking-history")
    } else if (pathname.includes("/audit-logs")) {
      setActiveTab("/audit-logs")
    }
  }, [pathname])

  return (
    <>
      <PageHeader
        title={title}
        breadcrums={[{ title: prevPage, path: path }, { title: "Details" }]}
      />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <StyledCard>
          <Paper
            elevation={2}
            style={{
              margin: "0  25px -10px 25px",
              backgroundColor: "#fff",
            }}
          >
            <Tabs value={activeTab} centered>
              <Tab
                label="Profile"
                value="/profile"
                component={Link}
                to={`${USERS}/${userId}/profile`}
              />
              <Tab
                label="Booking History"
                value="/booking-history"
                component={Link}
                to={`${USERS}/${userId}/booking-history`}
              />
              <Tab
                label="Audit Log"
                value="/audit-logs"
                component={Link}
                to={`${USERS}/${userId}/audit-logs`}
              />
            </Tabs>
          </Paper>
          <TabPanel value={activeTab} index="/profile">
            <Card
              style={{
                minWidth: isSmallScreen
                  ? 320
                  : isMediumScreen
                  ? 500
                  : isLargeScreen
                  ? 800
                  : 800,
                backgroundColor: "#fff",
                padding: "0 20px",
                marginTop: "10px",
              }}
              elevation={2}
            >
              <Profile isSmallScreen={isSmallScreen} userId={userId} />
            </Card>
          </TabPanel>
          <TabPanel value={activeTab} index="/booking-history">
            {activeTab === "/booking-history" && (
              <Suspense fallback={<div>Loading...</div>}>
                <BookingHistory />
              </Suspense>
            )}
          </TabPanel>
          <TabPanel value={activeTab} index="/audit-logs">
            {activeTab === "/audit-logs" && (
              <Suspense fallback={<div>Loading...</div>}>
                <AuditLogs isPartofUserProfile />
              </Suspense>
            )}
          </TabPanel>
        </StyledCard>
      </div>
    </>
  )
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index }) => {
  return (
    <Box hidden={value !== index} sx={{ p: 3 }}>
      {children}
    </Box>
  )
}

export default UserProfile
