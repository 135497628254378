import { MarkedStatus } from "../../constants/data"
import { getMarkedStatus } from "../../utils/transactionHelpers"
import { MarkedNoChip, MarkedPendingChip, MarkedYesChip } from "./styles"
import CircleIcon from "@mui/icons-material/Circle"

export const MarkedStatusChip: React.FC<any> = ({ row }) => {
  const markedStatus = getMarkedStatus(row.isMarked, row.isReconciled)
  switch (markedStatus) {
    case MarkedStatus.MARKED:
      return (
        <MarkedYesChip
          label="Yes"
          size="small"
          variant="outlined"
          icon={<CircleIcon fontSize="inherit" />}
        />
      )
    case MarkedStatus.PENDING:
      return (
        <MarkedPendingChip
          label="Pending"
          size="small"
          variant="outlined"
          icon={<CircleIcon fontSize="inherit" />}
        />
      )
    default:
      return (
        <MarkedNoChip
          label="No"
          size="small"
          variant="outlined"
          icon={<CircleIcon fontSize="inherit" />}
        />
      )
  }
}
