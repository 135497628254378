import { takeLatest, put, call } from "redux-saga/effects"
import {
  downloadCSVFile,
  downloadCSVFileFailed,
  downloadCSVFileSuccess,
  getBankList,
  getBankListFailed,
  getBankListSuccess,
  getPaymentMethods,
  getPaymentMethodsFailed,
  getPaymentMethodsSuccess,
  getProcessSchedule,
  getProcessScheduleFailed,
  getProcessScheduleSuccess,
  getTransactionList,
  getTransactionListFailed,
  getTransactionListPayloadType,
  getTransactionListSuccess,
  initiateProcessSchedule,
  uploadCSVFile,
  uploadCSVFileFailed,
  uploadCSVFilePayloadType,
  uploadCSVFileSuccess,
} from "../slices/transactionsSlice"
import {
  downloadCSVFileService,
  fetchProcessScheduleData,
  fetchTransactionListService,
  getBankListService,
  getPaymentMethodsService,
  intiateProcessSchedule,
  uploadCSVFileService,
} from "../services/transactionsService"
import {
  Bank,
  ProcessSchedule,
  Transaction,
} from "../models/transactions.model"
import { showToast } from "../slices/toastSlice"

export function* getTransactionListSaga(action: getTransactionListPayloadType) {
  try {
    const { data } = yield call(fetchTransactionListService, action)
    yield put(
      getTransactionListSuccess({
        transactionList: data?.data?.map((transaction: any) => ({
          ...new Transaction(transaction),
        })),
        totalRowCount: data?.pagination?.total_count,
      }),
    )
  } catch (err) {
    yield put(getTransactionListFailed())
  }
}

export function* uploadCSVFileSaga(action: uploadCSVFilePayloadType) {
  try {
    yield call(uploadCSVFileService, action)
    yield put(showToast({ toastType: "success", msg: "Successfully Uploaded" }))
    yield put(uploadCSVFileSuccess())
  } catch (err) {
    yield put(uploadCSVFileFailed())
  }
}

export function* downloadCSVFileSaga(action: getTransactionListPayloadType) {
  try {
    const { data } = yield call(downloadCSVFileService, action)
    const url = URL.createObjectURL(data)
    const a = document.createElement("a")
    a.href = url
    a.download = `${Date.now()}-transactions.xls`
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    URL.revokeObjectURL(url)

    yield put(
      showToast({ toastType: "success", msg: "Successfully Downloaded" }),
    )
    yield put(downloadCSVFileSuccess())
  } catch (err: any) {
    yield put(downloadCSVFileFailed())
  }
}

export function* getBankListSaga() {
  try {
    const { data } = yield call(getBankListService)
    yield put(
      getBankListSuccess({
        bankList: data?.data?.map((bank: any) => ({
          ...new Bank(bank),
        })),
      }),
    )
  } catch (err) {
    yield put(getBankListFailed())
  }
}

export function* getPaymentMethodsSaga() {
  try {
    const { data } = yield call(getPaymentMethodsService)
    yield put(
      getPaymentMethodsSuccess({
        paymentMethods: data?.data?.map(
          (method: { id: string; name: string }) => {
            return { key: method?.id, value: method?.name }
          },
        ),
      }),
    )
  } catch (err) {
    yield put(getPaymentMethodsFailed())
  }
}

export function* getProcessScheduleSaga() {
  try {
    const { data } = yield call(fetchProcessScheduleData)
    yield put(
      getProcessScheduleSuccess({
        processScheduleData: { ...new ProcessSchedule(data) },
      }),
    )
  } catch (err) {
    yield put(getProcessScheduleFailed())
  }
}

export function* initiateProcessScheduleSaga() {
  try {
    const { data } = yield call(intiateProcessSchedule)
    yield put(
      showToast({
        toastType: "success",
        msg: data.message,
      }),
    )
    yield put(
      getProcessScheduleSuccess({
        processScheduleData: { ...new ProcessSchedule(data) },
      }),
    )
  } catch (err) {
    yield put(getProcessScheduleFailed())
  }
}

export default function* transactionSaga() {
  yield takeLatest(getTransactionList, getTransactionListSaga)
  yield takeLatest(uploadCSVFile, uploadCSVFileSaga)
  yield takeLatest(downloadCSVFile, downloadCSVFileSaga)
  yield takeLatest(getBankList, getBankListSaga)
  yield takeLatest(getPaymentMethods, getPaymentMethodsSaga)
  yield takeLatest(getProcessSchedule, getProcessScheduleSaga)
  yield takeLatest(initiateProcessSchedule, initiateProcessScheduleSaga)
}
