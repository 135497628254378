import styled from "@emotion/styled"

const ElevatedCard = styled.div`
  width: max-content;
  height: max-content;
  overflow: auto;
  margin: 10px auto;
  padding: 20px;
  box-shadow: rgb(71 88 114 / 10%) 0px 2px 6px;
  border-radius: 6px;
  background: #fff;
`

export const HoveredElevatedCard = styled.div`
  width: max-content;
  height: max-content;
  overflow: auto;
  margin: 10px auto;
  padding: 20px;
  box-shadow: rgb(71 88 114 / 10%) 0px 2px 6px;
  border-radius: 6px;
  background: #fff;
  &:hover {
    box-shadow: rgb(71 88 114 / 20%) 0px 4px 12px;
    transform: translateY(-2px);
    cursor: pointer;
  }
`

export default ElevatedCard
