import { useNavigate, useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  fetchCenterDetails,
  resetData,
  selectCenters,
} from "../../slices/centersSlice"
import { Fragment, useEffect, useMemo } from "react"
import { Box, Button, Skeleton, Stack, Typography } from "@mui/material"
import PageHeader from "../../components/PageHeader/PageHeader"
import ElevatedCard from "../../components/ElevatedCard/ElevatedCard"
import { CenterDetailsGrid, FixedButtons, GoogleMapImage } from "./styles"
import { CENTERS } from "../../constants/path"
import Carousel from "react-material-ui-carousel"
import { Divider } from "../Login/styles"
import { selectLogin } from "../../slices/loginSlice"
import { UserRole } from "../../constants/data"
import { staticRelativeImageHandler } from "../../utils/imageHandler"

const CenterDetails = () => {
  const { centerDetails, isCenterDetailsLoading } =
    useAppSelector(selectCenters)
  const { role } = useAppSelector(selectLogin)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { centerId } = useParams()

  useEffect(() => {
    if (centerId) dispatch(fetchCenterDetails({ centerId }))
    return () => {
      dispatch(resetData())
    }
  }, [centerId, dispatch])

  const formattedNames = useMemo(() => {
    return (
      centerDetails?.gatekeeper_details
        ?.map(
          (gatekeeper) => `${gatekeeper.first_name} ${gatekeeper.last_name}`,
        )
        ?.join(", ") || ""
    )
  }, [centerDetails])

  return (
    <Box>
      <PageHeader
        title="Center Details"
        breadcrums={[{ title: "Centers", path: CENTERS }, { title: "Details" }]}
      />

      <ElevatedCard style={{ width: "80%" }}>
        {!isCenterDetailsLoading ? (
          <>
            <CenterDetailsGrid>
              <Typography>Center Name:</Typography>
              <Typography>{centerDetails?.name}</Typography>
              <Typography>Center Description:</Typography>
              <Typography>{centerDetails?.description}</Typography>
              <Typography>Rules:</Typography>
              <Typography>{centerDetails?.rules}</Typography>
              <Typography>Address:</Typography>
              <Typography>{centerDetails?.address}</Typography>
              <Typography>Location on map:</Typography>
              {centerDetails.google_maps_thumbnail_url ? (
                <GoogleMapImage
                  onClick={() => window.open(centerDetails?.google_maps_url)}
                  src={staticRelativeImageHandler(
                    centerDetails?.google_maps_thumbnail_url,
                  )}
                  alt={`map image`}
                />
              ) : (
                <Typography />
              )}
              <Typography>Open Time:</Typography>
              <Typography>{centerDetails?.working_hours_start}</Typography>
              <Typography>Close Time:</Typography>
              <Typography>{centerDetails?.working_hours_end}</Typography>
              <Typography>Daypass Availability:</Typography>
              <Typography>
                {centerDetails?.is_day_pass_enabled ? "Yes" : "No"}
              </Typography>
              <Typography>Daypass Price:</Typography>
              <Typography>{centerDetails?.day_pass_price}</Typography>
              <Typography>Amenities:</Typography>
              <Typography>{centerDetails?.amenities?.join(", ")}</Typography>
              <Typography>Gatekeeper Details:</Typography>
              <Typography>{formattedNames}</Typography>
            </CenterDetailsGrid>
            <Divider />
            <Typography variant="h5" sx={{ textAlign: "center", mb: 2 }}>
              Center Images
            </Typography>
            <Carousel interval={8000} animation="slide" navButtonsAlwaysVisible>
              {centerDetails.images.map((img, index) => (
                <Fragment key={img}>
                  <Stack gap={1}>
                    <img
                      src={staticRelativeImageHandler(img)}
                      alt={`Uploaded ${index}`}
                      height="300px"
                      style={{ objectFit: "contain" }}
                    />
                  </Stack>
                </Fragment>
              ))}
            </Carousel>
            <FixedButtons>
              <Button
                sx={{ mr: 2 }}
                variant="contained"
                color="primary"
                onClick={() => navigate(`${CENTERS}/${centerId}`)}
                disabled={role !== UserRole.ADMIN}
              >
                Edit
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => navigate(CENTERS)}
              >
                back
              </Button>
            </FixedButtons>
          </>
        ) : (
          <Stack gap={2}>
            <Skeleton variant="rectangular" height={100} />
            <Skeleton variant="rectangular" />
            <Skeleton variant="rectangular" height={50} />
            <Skeleton variant="rectangular" />
            <Skeleton variant="rectangular" />
            <Skeleton variant="rectangular" height={50} />
            <Skeleton variant="rectangular" />
            <Skeleton variant="rectangular" />
            <Skeleton variant="rectangular" height={50} />
            <Skeleton variant="rectangular" />
          </Stack>
        )}
      </ElevatedCard>
    </Box>
  )
}

export default CenterDetails
