// ProfileTabPanel.tsx
import React, { useEffect, useState } from "react"
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Stack,
  TextField,
} from "@mui/material"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import { KYCPlaceholder } from "./styles"
import DensityMediumIcon from "@mui/icons-material/DensityMedium"
import {
  fetchBookingDetails,
  resetBookingDetails,
  selectBookingHistory,
} from "../../slices/bookingHistorySlice"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  fetchRoles,
  selectUsers,
  updateUserRole,
} from "../../slices/usersSlice"
import { updateUserDetailsService } from "../../services/userService"
import { UserRole, UserStatus } from "../../constants/data"
import { selectLogin } from "../../slices/loginSlice"
import { staticRelativeImageHandler } from "../../utils/imageHandler"

type ProfileTabPanelProps = {
  isSmallScreen: boolean
  userId: string | undefined
}

interface ProfileDetails {
  firstName: string
  lastName: string
  email: string
  mobile: string
  role: string
  updatedStatus: string
}

export const ProfileImage = ({ imageUrl }: { imageUrl: string | null }) => {
  const commonStyles = {
    marginTop: "10px",
    borderRadius: "50%",
    width: "120px",
    height: "120px",
    alignSelf: "center",
  }

  if (imageUrl) {
    return <img src={imageUrl} alt="Profile Pic" style={commonStyles} />
  } else {
    return <AccountCircleIcon style={commonStyles} color="primary" />
  }
}

const ProfileTabPanel: React.FC<ProfileTabPanelProps> = ({
  isSmallScreen,
  userId,
}) => {
  const dispatch = useAppDispatch()
  const { bookingDetails, isDetailsLoading } =
    useAppSelector(selectBookingHistory)
  const { roles } = useAppSelector(selectUsers)
  const { role } = useAppSelector(selectLogin)

  useEffect(() => {
    setProfileDetails({
      firstName: bookingDetails.first_name || "",
      lastName: bookingDetails.last_name || "",
      email: bookingDetails.email || "",
      mobile: bookingDetails.mobile || "",
      role:
        bookingDetails.roles.length > 0
          ? bookingDetails.roles[0]?.id || ""
          : "",
      updatedStatus: bookingDetails.status,
    })
  }, [bookingDetails])

  const [profileDetails, setProfileDetails] = useState<ProfileDetails>({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    role: "",
    updatedStatus: "",
  })

  useEffect(() => {
    dispatch(fetchRoles())
  }, [dispatch])

  useEffect(() => {
    if (userId) dispatch(fetchBookingDetails({ userId }))
    return () => {
      dispatch(resetBookingDetails())
    }
  }, [dispatch, userId])

  // input change handler
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target

    // Check if the value contains only alphabetic characters
    if (/^[A-Za-z]*$/.test(value) || value === "") {
      setProfileDetails((prevValues) => ({
        ...prevValues,
        [name]: value,
      }))
    }
  }

  const handleStatusChange = (event: SelectChangeEvent<string>) => {
    const { value } = event.target

    setProfileDetails((prevValues) => ({
      ...prevValues,
      updatedStatus: value,
    }))
  }
  const handleUserRoleChange = (selectedValue: string | undefined) => {
    const roleId = selectedValue || ""

    setProfileDetails((prevValues) => ({
      ...prevValues,
      role: roleId,
    }))
  }

  const isSaveDisabled =
    profileDetails.firstName.trim() === "" ||
    profileDetails.lastName.trim() === "" ||
    role === UserRole.AUDITOR

  const handleSave = () => {
    // Perform saving logic
    updateUserDetailsService({
      payload: {
        userId: bookingDetails.id.toString(),
        ...profileDetails,
        page: 0,
        pageSize: 0,
      },
    })

    dispatch(
      updateUserRole({
        userId: bookingDetails.id.toString(),
        roleId: profileDetails.role,
        page: 0,
        pageSize: 0,
      }),
    )
  }

  return (
    <div style={{ width: "100%" }}>
      {isDetailsLoading ? (
        <Stack gap={2}>
          <Skeleton
            variant="circular"
            height={120}
            width={120}
            sx={{ alignSelf: "center" }}
          />
          <Skeleton variant="rectangular" height={50} />
          <Skeleton variant="rectangular" height={50} />
          <Skeleton variant="rectangular" height={50} />
          <Skeleton variant="rectangular" height={50} />
          <Skeleton variant="rectangular" height={150} />
        </Stack>
      ) : (
        <Stack gap={2} paddingBottom={"20px"}>
          <ProfileImage
            imageUrl={staticRelativeImageHandler(bookingDetails.profile_image)}
          />
          <Box
            sx={{
              display: "grid",
              columnGap: 3,
              rowGap: 2,
              gridTemplateColumns: !isSmallScreen ? "repeat(2, 9fr)" : "1fr",
              width: "100%",
            }}
          >
            <TextField
              label="First Name"
              name="firstName"
              size="small"
              fullWidth
              value={`${profileDetails.firstName}`}
              onChange={handleInputChange}
              required
            />
            <TextField
              label="Last Name"
              name="lastName"
              size="small"
              fullWidth
              value={`${profileDetails.lastName}`}
              onChange={handleInputChange}
              required
            />

            <TextField
              label="Email"
              name="email"
              size="small"
              fullWidth
              value={profileDetails.email || ""}
              aria-readonly
            />
            <TextField
              label="Mobile"
              name="mobile"
              size="small"
              fullWidth
              value={profileDetails.mobile || ""}
              aria-readonly
            />
            <FormControl size="small">
              <InputLabel id="role-type">Role</InputLabel>
              <Select
                labelId="role-type"
                id="role"
                label="Role"
                value={profileDetails.role || ""}
                onChange={(event) => {
                  handleUserRoleChange(event.target.value)
                }}
              >
                {roles.map((role) => (
                  <MenuItem key={role.id} value={role.id}>
                    {role.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth size="small">
              <InputLabel id="status-type">Status</InputLabel>
              <Select
                labelId="status-type"
                id="status-type-id"
                value={profileDetails.updatedStatus}
                label="Status"
                onChange={handleStatusChange}
              >
                {Object.values(UserStatus).map((status) => (
                  <MenuItem key={status} value={status}>
                    {status.charAt(0).toUpperCase() +
                      status.replace(/_/g, " ").slice(1).toLowerCase()}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Button
            style={{
              width: "50%",
              margin: "10px 0 10px 25%",
            }}
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={isSaveDisabled}
          >
            Save
          </Button>

          {bookingDetails.kyc_image ? (
            <img
              src={staticRelativeImageHandler(bookingDetails.kyc_image)}
              alt={`Profile Pic`}
              height={200}
              style={{ borderRadius: "6px" }}
            />
          ) : (
            <KYCPlaceholder
              direction={!isSmallScreen ? "row" : "column"}
              alignItems={isSmallScreen ? "center" : ""}
            >
              <AccountCircleIcon
                color="primary"
                style={{ height: "100px", width: "100px" }}
              />
              <div>
                {[1, 2, 3].map((key) => (
                  <DensityMediumIcon
                    key={key}
                    style={{
                      height: "100px",
                      width: "100px",
                      marginLeft: key === 1 ? "0" : "-26px",
                      color: "#e4e4e4",
                    }}
                  />
                ))}
              </div>
            </KYCPlaceholder>
          )}
        </Stack>
      )}
    </div>
  )
}

export default ProfileTabPanel
