import { all } from "redux-saga/effects"
import loginSaga from "../sagas/loginSaga"
import daypassBookingSaga from "../sagas/daypassBookingSaga"
import bookingHistorySaga from "../sagas/bookingHistorySaga"
import centerSaga from "../sagas/centerSaga"
import meetingRoomsSaga from "../sagas/meetingRoomsSaga"
import usersSaga from "../sagas/usersSaga"
import auditLogsSaga from "../sagas/auditLogsSaga"
import transactionSaga from "../sagas/transactionSaga"
import bankAccountsSaga from "../sagas/bankAccountsSaga"
import { reconciliationSaga } from "../sagas/reconciliationSaga"

export default function* rootSaga() {
  yield all([
    loginSaga(),
    daypassBookingSaga(),
    bookingHistorySaga(),
    centerSaga(),
    meetingRoomsSaga(),
    usersSaga(),
    auditLogsSaga(),
    transactionSaga(),
    bankAccountsSaga(),
    reconciliationSaga(),
  ])
}
