import { Button } from "@mui/material"
import { styled } from "@mui/system"

export const StyledButton = styled(Button)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "24px",
  backgroundColor: "white",
  padding: "10px 18px",
  borderRadius: "8px",
  color: "#263238",
  border: "1px solid #d0d5dd",
  textTransform: "unset",
  boxShadow: "0px 1px 2px 0px #0000000d",
  [theme.breakpoints.down("sm")]: {
    flex: 1,
    fontSize: "12px",
  },
}))

export const PrimaryButton = styled(StyledButton)`
  background-color: #ffc100;
  border: none;
`

export const ReconcilePrimaryButton = styled(PrimaryButton)`
  width: 100%;
  gap: 8px;
  margin-top: 12px;
`
