import { useLocation, useNavigate } from "react-router-dom"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import PageHeader from "../../components/PageHeader/PageHeader"
import {
  BoldSpan,
  ButtonContainer,
  ContainerCardBooking,
  ContainerCardCenter,
  ContainerCardHolder,
  ContainerCardPay,
  ContainerCardPayments,
  ContainerCardUser,
  DetailsContainer,
  DetailsDiv,
  EllipsisDiv,
  FlexDiv,
  HoldingContainer,
  HoldingContainerCenter,
  HoldingContainerUserImage,
  ImgSkeleton,
  InnerStyledHeading,
  ParaTag,
  ParaTagClick,
  StyledHeading,
  StyledImg,
} from "./styles"
import {
  bookingListType,
  fetchBookingDetailsById,
  selectBookingHistory,
  updateOrderDetails,
  updateOrderDetailsMarkAsActive,
} from "../../slices/bookingHistorySlice"
import { CENTERS } from "../../constants/path"
import { USERS } from "../../constants/apis"
import { ProfileImage } from "../UserProfile/Profile"
import { Button, CircularProgress, Container, Skeleton } from "@mui/material"
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded"
import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import isEmpty from "lodash/isEmpty"
import { staticRelativeImageHandler } from "../../utils/imageHandler"
import { selectLogin } from "../../slices/loginSlice"
import { UserRole } from "../../constants/data"

dayjs.extend(utc)

interface IPaymentAttempt {
  id: string
  amount: number
  amount_refunded: number
  card_id: string
  created_at: number
  email: string
  error_code: string | null
  error_description: string | null
  error_reason: string | null
  error_source: string | null
  method: string | null
  status: string | null
  acquirer_data: {
    rrn: string | null
  }
}

const BookingHistoryDetails = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { title, prevPage, path, item } = (location.state as {
    title: string
    prevPage: string
    path: string
    item: bookingListType
  }) || { title: "Profile", prevPage: "Booking History", path: "/", item: {} }

  const { bookingDetailsById, orderDetails, isDetailsByIdLoading } =
    useAppSelector(selectBookingHistory)

  const { role } = useAppSelector(selectLogin)

  useEffect(() => {
    handleUpdateDetailsClick()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleImageClick = () => {
    navigate(`${CENTERS}/details/${bookingDetailsById?.center_details?.id}`)
  }

  const handleUpdateDetailsClick = () => {
    dispatch(fetchBookingDetailsById({ bookingId: item?.id }))
    dispatch(updateOrderDetails({ bookingId: item?.id }))
  }

  const handleMarkAsActiveClick = () => {
    dispatch(updateOrderDetailsMarkAsActive({ bookingId: item?.id }))
  }

  const handleUserClick = (id: string | undefined | null) => {
    navigate(`${USERS}/${id}/profile`)
  }

  const isMarkActiveButtonDisabled = () => {
    if (
      role === UserRole.ADMIN &&
      orderDetails?.order_details?.status === "paid" &&
      !orderDetails?.is_active &&
      dayjs(orderDetails?.booking_date).isSame(dayjs(), "day") &&
      orderDetails?.order_details !== null &&
      !isEmpty(orderDetails?.order_details?.payments)
    ) {
      return false
    }
    return true
  }
  return (
    <>
      <PageHeader
        title={title}
        breadcrums={[
          { title: prevPage, path: path },
          { title: "Booking Details" },
        ]}
      />

      <Container>
        <ButtonContainer>
          <Button
            sx={{ mr: 1, width: "150px" }}
            variant="outlined"
            startIcon={isDetailsByIdLoading ? null : <RefreshRoundedIcon />}
            disabled={isDetailsByIdLoading}
            onClick={handleUpdateDetailsClick}
            style={{ overflow: "hidden" }}
          >
            {isDetailsByIdLoading ? (
              <CircularProgress size={25} color="inherit" />
            ) : (
              "Refresh"
            )}
          </Button>
          <Button
            variant="contained"
            sx={{ width: "150px" }}
            size="small"
            disabled={isMarkActiveButtonDisabled()}
            onClick={handleMarkAsActiveClick}
          >
            Mark as Active
          </Button>
        </ButtonContainer>

        <FlexDiv>
          <ContainerCardBooking>
            <StyledHeading>Booking Details</StyledHeading>
            <DetailsContainer>
              {isDetailsByIdLoading ? (
                <HoldingContainer>
                  {Array(16)
                    .fill(null)
                    .map((_, index) => (
                      <ParaTag key={index}>
                        <Skeleton animation="wave" />
                      </ParaTag>
                    ))}
                </HoldingContainer>
              ) : (
                <HoldingContainer>
                  <ParaTag>
                    <BoldSpan>Id:</BoldSpan> {bookingDetailsById?.id}
                  </ParaTag>
                  <ParaTag>
                    <BoldSpan>Amount:</BoldSpan> ₹{bookingDetailsById?.amount}
                  </ParaTag>
                  <ParaTag>
                    <BoldSpan>Code:</BoldSpan>{" "}
                    {bookingDetailsById?.booking_code}
                  </ParaTag>
                  <ParaTag>
                    <BoldSpan>Date:</BoldSpan>{" "}
                    {dayjs(bookingDetailsById?.booking_date).format(
                      " dddd, D MMMM YYYY",
                    )}
                  </ParaTag>
                  <ParaTag>
                    <BoldSpan>Created At:</BoldSpan>{" "}
                    {dayjs
                      .utc(bookingDetailsById?.day_passes_data.created_at)
                      .utcOffset("+05:30")
                      .format("hh:mm A, dddd, D MMMM YYYY")}
                  </ParaTag>
                  <ParaTag>
                    <BoldSpan>Reference:</BoldSpan>{" "}
                    {bookingDetailsById?.booking_ref}
                  </ParaTag>
                  <ParaTag>
                    <BoldSpan>Status:</BoldSpan>{" "}
                    {bookingDetailsById?.is_active ? "Active" : "Inactive"}
                  </ParaTag>
                  <ParaTag>
                    <BoldSpan>Type:</BoldSpan>{" "}
                    {bookingDetailsById?.daypass_type}
                  </ParaTag>
                  <ParaTag>
                    <BoldSpan>Is Gifted Pass:</BoldSpan>{" "}
                    {bookingDetailsById?.created_by ===
                    bookingDetailsById?.user_id
                      ? "No"
                      : "Yes"}
                  </ParaTag>
                  {bookingDetailsById?.created_by !==
                    bookingDetailsById?.user_id && (
                    <ParaTag>
                      <BoldSpan>Gifter Id:</BoldSpan>{" "}
                      <ParaTagClick
                        onClick={() =>
                          handleUserClick(bookingDetailsById?.created_by)
                        }
                      >
                        {bookingDetailsById?.created_by}
                      </ParaTagClick>
                    </ParaTag>
                  )}
                  <ParaTag>
                    <BoldSpan>Payment Status:</BoldSpan>{" "}
                    {bookingDetailsById?.payment_status}
                  </ParaTag>

                  <ParaTag>
                    <BoldSpan>Day Pass Id:</BoldSpan>{" "}
                    {bookingDetailsById?.day_passes_data?.id}
                  </ParaTag>
                  <ParaTag>
                    <BoldSpan>Pass Count(Used / Total):</BoldSpan>{" "}
                    {bookingDetailsById?.day_passes_data?.used_pass_count}/
                    {bookingDetailsById?.day_passes_data?.total_pass_count}
                  </ParaTag>

                  <ParaTag>
                    <BoldSpan>Pass Status:</BoldSpan>{" "}
                    {bookingDetailsById?.day_passes_data?.pass_status}
                  </ParaTag>
                  <ParaTag>
                    <BoldSpan>Start Date:</BoldSpan>{" "}
                    {dayjs(
                      bookingDetailsById?.day_passes_data?.start_date,
                    ).format(" dddd, D MMMM YYYY")}
                  </ParaTag>
                  <ParaTag>
                    <BoldSpan>End Date:</BoldSpan>{" "}
                    {dayjs(
                      bookingDetailsById?.day_passes_data?.end_date,
                    ).format(" dddd, D MMMM YYYY")}
                  </ParaTag>
                </HoldingContainer>
              )}
            </DetailsContainer>
          </ContainerCardBooking>
          <ContainerCardHolder>
            <ContainerCardCenter>
              <StyledHeading>Center Details</StyledHeading>

              <DetailsContainer>
                <HoldingContainerCenter onClick={handleImageClick}>
                  {isDetailsByIdLoading ? (
                    <ImgSkeleton>
                      <Skeleton
                        variant="rectangular"
                        width={350}
                        height={100}
                      />
                    </ImgSkeleton>
                  ) : (
                    <StyledImg
                      alt="center_image"
                      src={staticRelativeImageHandler(
                        bookingDetailsById?.center_details?.images[0],
                      )}
                    ></StyledImg>
                  )}
                </HoldingContainerCenter>
                {isDetailsByIdLoading ? (
                  <DetailsDiv>
                    {Array(4)
                      .fill(null)
                      .map((_, index) => (
                        <ParaTag key={index}>
                          <Skeleton animation="wave" />
                        </ParaTag>
                      ))}
                  </DetailsDiv>
                ) : (
                  <DetailsDiv>
                    <ParaTag>
                      <BoldSpan>Name:</BoldSpan>{" "}
                      {bookingDetailsById?.center_details?.name}
                    </ParaTag>
                    <EllipsisDiv>
                      <BoldSpan>Address:</BoldSpan>{" "}
                      {bookingDetailsById?.center_details?.address}
                    </EllipsisDiv>

                    <ParaTag>
                      <BoldSpan>Center Id:</BoldSpan>{" "}
                      <ParaTagClick onClick={handleImageClick}>
                        {bookingDetailsById?.center_id}
                      </ParaTagClick>
                    </ParaTag>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={bookingDetailsById?.center_details?.google_maps_url}
                    >
                      <ParaTag>
                        <BoldSpan>Google Map URL</BoldSpan>{" "}
                      </ParaTag>
                    </a>
                  </DetailsDiv>
                )}
              </DetailsContainer>
            </ContainerCardCenter>
            <ContainerCardUser>
              <StyledHeading>User Details</StyledHeading>
              <DetailsContainer>
                <>
                  {isDetailsByIdLoading ? (
                    <Skeleton variant="circular" width={120} height={120} />
                  ) : (
                    <HoldingContainerUserImage
                      onClick={() =>
                        handleUserClick(bookingDetailsById?.user_details?.id)
                      }
                    >
                      <ProfileImage
                        imageUrl={
                          bookingDetailsById?.user_details?.profile_image ??
                          null
                        }
                      />
                    </HoldingContainerUserImage>
                  )}
                </>

                {isDetailsByIdLoading ? (
                  <DetailsDiv>
                    {Array(4)
                      .fill(null)
                      .map((_, index) => (
                        <ParaTag key={index}>
                          <Skeleton animation="wave" />
                        </ParaTag>
                      ))}
                  </DetailsDiv>
                ) : (
                  <DetailsDiv>
                    <ParaTag>
                      <BoldSpan>Name:</BoldSpan>{" "}
                      {bookingDetailsById?.user_details?.first_name}{" "}
                      {bookingDetailsById?.user_details?.last_name}
                    </ParaTag>
                    <ParaTag>
                      <BoldSpan>Email:</BoldSpan>{" "}
                      {bookingDetailsById?.user_details?.email}
                    </ParaTag>
                    <ParaTag>
                      <BoldSpan>Mobile Number:</BoldSpan>{" "}
                      {bookingDetailsById?.user_details?.country_code}-
                      {bookingDetailsById?.user_details?.mobile}
                    </ParaTag>

                    <ParaTag>
                      <BoldSpan>User Id:</BoldSpan>{" "}
                      <ParaTagClick
                        onClick={() =>
                          handleUserClick(bookingDetailsById?.user_details?.id)
                        }
                      >
                        {bookingDetailsById?.user_details?.id}
                      </ParaTagClick>
                    </ParaTag>
                  </DetailsDiv>
                )}
              </DetailsContainer>
            </ContainerCardUser>
          </ContainerCardHolder>
        </FlexDiv>
        <>
          <ContainerCardPayments>
            <StyledHeading>Payment Details</StyledHeading>
            {isDetailsByIdLoading ? (
              <>
                {Array(4)
                  .fill(null)
                  .map((_, index) => (
                    <ParaTag key={index}>
                      <Skeleton animation="wave" />
                    </ParaTag>
                  ))}
              </>
            ) : (
              <DetailsContainer>
                <HoldingContainer>
                  <ParaTag>
                    <BoldSpan>Id:</BoldSpan> {orderDetails?.order_details?.id}
                  </ParaTag>
                  <ParaTag>
                    <BoldSpan>Amount:</BoldSpan> ₹
                    {(orderDetails?.order_details?.amount ?? 0) / 100}
                  </ParaTag>
                  <ParaTag>
                    <BoldSpan>Amount(Due / Paid):</BoldSpan> ₹
                    {(orderDetails?.order_details?.amount_due ?? 0) / 100} / ₹
                    {(orderDetails?.order_details?.amount_paid ?? 0) / 100}
                  </ParaTag>
                </HoldingContainer>
                <DetailsDiv>
                  <ParaTag>
                    <BoldSpan>Created At:</BoldSpan>{" "}
                    {dayjs
                      .unix(orderDetails?.order_details?.created_at ?? 0)
                      .utcOffset("+05:30")
                      .format("hh:mm A, dddd, D MMMM YYYY")}
                  </ParaTag>
                  <ParaTag>
                    <BoldSpan>Receipt:</BoldSpan>{" "}
                    {orderDetails?.order_details?.receipt}
                  </ParaTag>
                  <ParaTag>
                    <BoldSpan>Status:</BoldSpan>{" "}
                    {orderDetails?.order_details?.status}
                  </ParaTag>
                </DetailsDiv>
              </DetailsContainer>
            )}
            <StyledHeading>Payment Attempts</StyledHeading>
            {isDetailsByIdLoading ? (
              <>
                <Skeleton variant="rectangular" height={260} />
              </>
            ) : (
              <FlexDiv>
                {orderDetails?.order_details?.payments?.map(
                  (paymentAttempt: IPaymentAttempt, index: number) => (
                    <ContainerCardPay key={paymentAttempt.id}>
                      <InnerStyledHeading>
                        Payment Attempt #{index + 1}
                      </InnerStyledHeading>
                      <ParaTag>
                        <BoldSpan>Id:</BoldSpan> {paymentAttempt?.id}
                      </ParaTag>
                      <ParaTag>
                        <BoldSpan>Status:</BoldSpan> {paymentAttempt?.status}
                      </ParaTag>
                      <ParaTag>
                        <BoldSpan>Amount:</BoldSpan> ₹
                        {(paymentAttempt?.amount ?? 0) / 100}
                      </ParaTag>
                      <ParaTag>
                        <BoldSpan>Method:</BoldSpan> {paymentAttempt?.method}
                      </ParaTag>
                      {paymentAttempt?.acquirer_data?.rrn && (
                        <ParaTag>
                          <BoldSpan>Acquirer RRN:</BoldSpan>{" "}
                          {paymentAttempt?.acquirer_data?.rrn}
                        </ParaTag>
                      )}
                      <ParaTag>
                        <BoldSpan>Created At:</BoldSpan>{" "}
                        {dayjs
                          .unix(paymentAttempt?.created_at)
                          .utcOffset("+05:30")
                          .format("hh:mm A, dddd, D MMMM YYYY")}
                      </ParaTag>
                      <ParaTag>
                        <BoldSpan>Email:</BoldSpan> {paymentAttempt?.email}
                      </ParaTag>
                      {paymentAttempt?.error_code && (
                        <ParaTag>
                          <BoldSpan>Error Code:</BoldSpan>{" "}
                          {paymentAttempt?.error_code}
                        </ParaTag>
                      )}
                      {paymentAttempt?.error_description && (
                        <ParaTag>
                          <BoldSpan>Error Description:</BoldSpan>{" "}
                          {paymentAttempt?.error_description}
                        </ParaTag>
                      )}
                      {paymentAttempt?.error_reason && (
                        <ParaTag>
                          <BoldSpan>Error Reason:</BoldSpan>{" "}
                          {paymentAttempt?.error_reason}
                        </ParaTag>
                      )}
                      {paymentAttempt?.error_source && (
                        <ParaTag>
                          <BoldSpan>Error Source:</BoldSpan>{" "}
                          {paymentAttempt?.error_source}
                        </ParaTag>
                      )}
                    </ContainerCardPay>
                  ),
                )}
              </FlexDiv>
            )}
          </ContainerCardPayments>
        </>
      </Container>
    </>
  )
}

export default BookingHistoryDetails
