import React from "react"
import { formatPriceWithRupeeSymbol } from "../../utils/price-formatter"
import {
  TransactionDetailsCard,
  DetailLabel,
  AmountContainer,
  BalanceAmount,
  TransactionAmount,
  ErrorText,
  Description,
  StyledDivider,
} from "./styles"

interface TransactionDetailsProps {
  amount: number
  totalAmount: number
  description: string
  error?: string
}

export const TransactionDetails: React.FC<TransactionDetailsProps> = ({
  amount,
  totalAmount,
  description,
  error,
}) => (
  <TransactionDetailsCard>
    <DetailLabel>Amount</DetailLabel>
    <AmountContainer>
      <BalanceAmount component="span" error={Boolean(error)}>
        {formatPriceWithRupeeSymbol(amount)}
      </BalanceAmount>
      <TransactionAmount component="span">
        {" "}
        / {formatPriceWithRupeeSymbol(totalAmount)}
      </TransactionAmount>
      {Boolean(error) && <ErrorText>{error}</ErrorText>}
    </AmountContainer>
    {Boolean(description) && (
      <>
        <StyledDivider />
        <DetailLabel>Description</DetailLabel>
        <Description>{description}</Description>
      </>
    )}
  </TransactionDetailsCard>
)
