import React, { useEffect } from "react"
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Button,
  SelectChangeEvent,
  TextField,
} from "@mui/material"
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded"
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined"
import {
  BookingRefType,
  DaypassType,
  PaymentStatus,
} from "../../constants/data"
import DateRangeSelector from "../DateRangeSelector/DateRangeSelector"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { selectCenters } from "../../slices/centersSlice"
import { useParams } from "react-router-dom"
import ElevatedCard from "../ElevatedCard/ElevatedCard"
import {
  selectBookingHistory,
  setFilters,
} from "../../slices/bookingHistorySlice"
import { Dayjs } from "dayjs"

interface BookingHistoryFilterProps {
  isSmallScreen: boolean
  handleRefresh: () => void
  resetFilterHandler: () => void
}

const BookingHistoryFilter: React.FC<BookingHistoryFilterProps> = ({
  isSmallScreen,
  handleRefresh,
  resetFilterHandler,
}) => {
  const { centers, isCenterLoading } = useAppSelector(selectCenters)
  const { userId } = useParams()
  const { filters } = useAppSelector(selectBookingHistory)
  const dispatch = useAppDispatch()
  const [isBookingCodeValid, setIsBookingCodeValid] = React.useState(true)

  const options = [{ name: "All", id: "All" }, ...centers]

  // type dropdown handler
  const handleTypeChange = (event: SelectChangeEvent<typeof filters.type>) => {
    const {
      target: { value },
    } = event
    dispatch(
      setFilters({
        ...filters,

        type: typeof value === "string" ? value.split(",") : value,
      }),
    )
  }

  //handle payment status change
  const handlePaymentChange = (
    event: SelectChangeEvent<typeof filters.paymentStatus>,
  ) => {
    const {
      target: { value },
    } = event
    dispatch(
      setFilters({
        ...filters,
        paymentStatus: typeof value === "string" ? value.split(",") : value,
      }),
    )
  }

  //handle Booking ref change
  const handleBookingRefChange = (
    event: SelectChangeEvent<typeof filters.bookingRef>,
  ) => {
    const {
      target: { value },
    } = event
    dispatch(
      setFilters({
        ...filters,
        bookingRef: value,
      }),
    )
  }

  const handleStatusChange = (event: SelectChangeEvent<string>) => {
    const { value } = event.target
    dispatch(
      setFilters({
        ...filters,

        isActive:
          value === "active" ? true : value === "inactive" ? false : null,
      }),
    )
  }

  const handleDateRangeSelection = (newState: {
    dateSelector: string
    startDate?: Dayjs | null
    endDate?: Dayjs | null
  }) => {
    dispatch(
      setFilters({
        ...filters,
        ...newState,
      }),
    )
  }

  const handleBookingCodeInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    const newBookingCode = event.target.value
    dispatch(
      setFilters({
        ...filters,
        bookingCode: newBookingCode,
      }),
    )
    setIsBookingCodeValid(
      newBookingCode.length === 8 && newBookingCode.length > 0,
    )
  }

  useEffect(() => {
    setIsBookingCodeValid(
      filters.bookingCode?.length === 0 || filters.bookingCode?.length === 8,
    )
  }, [filters.bookingCode])

  return (
    <ElevatedCard
      style={{ width: userId ? "95%" : isSmallScreen ? "90%" : "600px" }}
    >
      <Box
        sx={{
          display: "grid",
          columnGap: 3,
          rowGap: 2,
          gridTemplateColumns: isSmallScreen ? "1fr" : "repeat(3, 9fr)",
        }}
      >
        <FormControl fullWidth size="small">
          <InputLabel id="select-center">Center</InputLabel>
          <Select
            labelId="select-center"
            id="center"
            label="Center"
            value={filters.center || ""}
            onChange={(e) =>
              dispatch(
                setFilters({
                  ...filters,

                  center: e.target.value as string,
                }),
              )
            }
            disabled={isCenterLoading}
          >
            {isCenterLoading ? (
              <MenuItem value="">Loading...</MenuItem>
            ) : (
              options.map((center) => (
                <MenuItem key={center.id} value={center.id}>
                  {center.name}
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>

        <FormControl fullWidth size="small">
          <InputLabel id="booking-type">Booking Reference</InputLabel>
          <Select
            labelId="booking-type"
            id="booking-type-id"
            value={filters.bookingRef || ""}
            label="Booking reference"
            onChange={handleBookingRefChange}
          >
            {Object.values(BookingRefType).map((status) => (
              <MenuItem key={status} value={status}>
                {status.charAt(0) + status.slice(1).toLowerCase()}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth size="small">
          <InputLabel id="payment-type">Payment Status</InputLabel>
          <Select
            labelId="payment-type"
            id="payment-type-id"
            multiple
            value={filters.paymentStatus}
            label="Payment Type"
            onChange={handlePaymentChange}
          >
            {Object.values(PaymentStatus).map((status) => (
              <MenuItem key={status} value={status}>
                {status.charAt(0) + status.slice(1).toLowerCase()}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <DateRangeSelector
          dateSelector={filters.dateSelector}
          filterState={filters}
          updateFilterState={handleDateRangeSelection}
        />

        <FormControl fullWidth size="small">
          <InputLabel id="select-type">Type</InputLabel>
          <Select
            labelId="select-type"
            id="select-type-id"
            multiple
            value={filters.type}
            label="Type"
            onChange={handleTypeChange}
          >
            <MenuItem value={DaypassType.DAYPASS}>Daypass</MenuItem>
            <MenuItem value={DaypassType.BULKDAYPASS}>Bulk Daypass</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth size="small">
          <InputLabel id="status-type">Booking Status</InputLabel>
          <Select
            labelId="status-type"
            id="status-type-id"
            value={
              filters.isActive === null
                ? "none"
                : filters.isActive
                ? "active"
                : "inactive"
            }
            label="Booking Status"
            onChange={handleStatusChange}
          >
            <MenuItem value="none">None</MenuItem>
            <MenuItem value="active">Active</MenuItem>
            <MenuItem value="inactive">Inactive</MenuItem>
          </Select>
        </FormControl>
        <FormControl>
          <TextField
            label="Booking Code"
            name="booking_code"
            type="text"
            size="small"
            error={!isBookingCodeValid}
            helperText={isBookingCodeValid ? "" : "Invalid code"}
            value={filters?.bookingCode}
            onChange={handleBookingCodeInputChange}
          />
        </FormControl>
      </Box>

      <Box
        sx={{
          display: "grid",
          columnGap: 3,
          rowGap: 1,
          gridTemplateColumns: isSmallScreen ? "1fr" : "1fr",
          width: "100%",
        }}
      >
        <Stack
          direction="row"
          gap={2}
          justifyContent={isSmallScreen ? "center" : "flex-end"}
        >
          <Stack
            direction="row"
            gap={2}
            marginTop={"20px"}
            alignItems="center"
            justifyContent="center"
          >
            <Tooltip title="Refresh">
              <Button
                variant="outlined"
                startIcon={<RefreshRoundedIcon />}
                onClick={handleRefresh}
              >
                Refresh
              </Button>
            </Tooltip>
            <Tooltip title="Reset Filter" arrow>
              <Button
                variant="outlined"
                startIcon={<CachedOutlinedIcon color="primary" />}
                onClick={() => resetFilterHandler()}
              >
                Reset
              </Button>
            </Tooltip>
          </Stack>
        </Stack>
      </Box>
    </ElevatedCard>
  )
}

export default BookingHistoryFilter
